export default [
  "Apple",
  "Bronica",
  "Canon",
  "DJI",
  "Fujifilm",
  "GoPro",
  "Google",
  "HMD Global",
  "HUAWEI",
  "Hasselblad",
  "LGE",
  "Leica",
  "Mamiya",
  "Minolta",
  "Moment",
  "Nikon",
  "OOWA",
  "OnePlus",
  "Parrot",
  "Pentax",
  "Samsung",
  "Sigma",
  "Sony",
  "Tamron",
  "Yuneec",
  "Zeiss"
];
