export default [
  "ƒ/1",
  "ƒ/1.1",
  "ƒ/1.2",
  "ƒ/1.4",
  "ƒ/1.6",
  "ƒ/1.8",
  "ƒ/2",
  "ƒ/2.2",
  "ƒ/2.5",
  "ƒ/2.8",
  "ƒ/3.2",
  "ƒ/3.5",
  "ƒ/4",
  "ƒ/4.5",
  "ƒ/5",
  "ƒ/5.6",
  "ƒ/6.3",
  "ƒ/7.1",
  "ƒ/8",
  "ƒ/9",
  "ƒ/10",
  "ƒ/11",
  "ƒ/13",
  "ƒ/14",
  "ƒ/16",
  "ƒ/18",
  "ƒ/20",
  "ƒ/22",
  "ƒ/25",
  "ƒ/29",
  "ƒ/32",
  "ƒ/36"
]
