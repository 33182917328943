export default {
  apiKey: "AIzaSyAmVWAPTXaccP_j52dVJwzEyjHO-XTUoxI",
  authDomain: "gagunk-app.firebaseapp.com",
  databaseURL: "https://gagunk-app.firebaseio.com",
  projectId: "gagunk-app",
  storageBucket: "gagunk-app.appspot.com",
  messagingSenderId: "214279252823",
  appId: "1:214279252823:web:d97b07d9c4d3684878ec12",
  measurementId: "G-C9CM4WXRJ7"
};
