export default [
  "8mm",
  "16mm",
  "18mm",
  "20mm",
  "24mm",
  "28mm",
  "35mm",
  "50mm",
  "55mm",
  "70mm",
  "85mm",
  "100mm",
  "135mm",
  "200mm",
  "210mm"
];
